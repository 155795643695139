import { Candidate, CandidateProfiles } from './../../app/core/models/candidate';
import { APIResponse, APIResponseDuplicate } from './../../app/core/models/response.model';
import { Address } from './../../app/core/models/address.model';
import { CandidateContactInfo } from './../../app/core/models/candidate-contact-info.model';
import { AccountManagerClients } from './../../app/core/models/account-manager-clients.model';
import { ClientContact } from 'src/app/core/models/client-contact';
import { Extension } from 'src/app/core/models/extension.model';

const hrefUrl = 'https://cartuspoc.okta.com/api/v1/authn/cancel';
const zoneTime = 'America/Los_Angeles';
// eslint-disable-next-line max-len
const accessToken = 'eyJraWQiOiJSWVhBcUZ5azBOMG4wbjRGeDJ0SlkwU0gzcFNWYnRhZWlKeVdiYmkzN3pVIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULm9YaFBxR2F4SmxNOVhXLVVrQlRCSXl1SmZTQWh1ZllQUDJvcjZxVVpSOEUiLCJpc3MiOiJodHRwczovL2xvZ2lucHJldmlldy5jYXJ0dXMuY29tL29hdXRoMi9hdXNweWUybnV6U0dKMEtqZTBoNyIsImF1ZCI6ImNhcnR1c1VzZXJzIiwiaWF0IjoxNjYzMjYyNzA2LCJleHAiOjE2NjMyNjQ1MDYsImNpZCI6IjBvYXB5Y216NTFHMDN1azNiMGg3IiwidWlkIjoiMDB1cWhyZzk3c3NGRlVSWnkwaDciLCJzY3AiOlsib3BlbmlkIiwiZW1haWwiXSwiYXV0aF90aW1lIjoxNjYzMjYyNjk5LCJzdWIiOiJtYXJ0aGEuYWd1aWxlcmEuZHZAdGVzdC5jb20ifQ.oVkNtvuvyOFUiiztkAXgw8w2AG3XJ5brzlmSWJ_Qa86plEFexaldyqdqss2jT1cVESuHtNXQcigfrj-artrMg6-53zMfE7g8bxPoHheuIa0z8EEL0dZ8DgJwi2fhGWnLndRYo7owIusR03vQBoRrdngeOfT2B4wgPZwIUj4SVXXTh92bZbq5qol99kwr9PKHZ9jIVqQRH6D2PbS6jQ3kD2hKYr4Qj5VObqVhR86dIjLcUUlZut_s8ubkWeis7u_ewnJ9v3xhX_CEcnR3QbQmX_pJvbg72cDm_XMPsFhag9Q0GdjjgyG2GwmKhr-XFZs2h5YozxlbT6rfASbNNgki8Q';
const idToken = 'eyJraWQiOiJ4U21iVVhZOUJod2VXV1lLZktCWWoyZU';
const oktaLink = 'https://loginpreview.cartus.com/oauth2/auspye2nuzSGJ0Kje0h7';
const tokenExpiry = 1608665092;
const validationToken = {
    accessToken: {
        accessToken: accessToken,
        value: accessToken
    },
    idToken: {
        idToken: idToken,
        value: idToken
    }
};
const validEmail = 'TEST_mohan.ashokan@mindtree.com';
const oktaToken = {
    code: '_xz74Ldw03iu3qEkLwks4bUrJyugUg7VdinqGrt2RDX',
    state: undefined,
    tokens: {
        accessToken: {
            accessToken: accessToken,
            authorizeUrl: `${oktaLink}/v1/authorize`,
            claims: {
                aud: 'cartusUsers',
                cid: '34jkop4uj09u4589035r',
                exp: tokenExpiry,
                iat: tokenExpiry,
                iss: oktaLink,
                jti: 'ID.PHIOUEWYR09u78324_oiu90-3424u890843y76D3qdx',
                sAMAccountName: 'mohan.ashokan',
                scp: ['openid', 'email'],
                sub: 'mohan.ashokan@mindtree.com',
                uid: '000uopy877438jndfsaW',
                ver: 1,
            },
            expiresAt: tokenExpiry,
            scopes: ['openid', 'email'],
            tokenType: 'Bearer',
            userinfoUrl: `${oktaLink}/v1/userinfo`,
            value: accessToken,
        },
        idToken: {
            authorizeUrl: `${oktaLink}/v1/authorize`,
            claims: {
                amr: ['pwd'],
                /* eslint-disable */
                at_hash: 'wLltFKL1oYihUOU-513s2Q',
                aud: '34jkop4uj09u4589035r',
                auth_time: tokenExpiry,
                /* eslint-enable */
                email: 'TEST_mohan.ashokan@mindtree.com',
                exp: tokenExpiry,
                iat: tokenExpiry,
                idp: '00wer9890fdsskl238s3',
                iss: oktaLink,
                jti: 'ID.PHIOUEWYR09u78324_oiu90-3424u890843y76D3qdx',
                nonce: 'qwjuewrklnjkoldscjDJNKD89080d9809890JDJDDD87902833KDWKL908Vldd0V',
                sub: '000uopy877438jndfsaW',
                ver: 1,
            },
            clientId: '0odj9873jd9sdf2334i6',
            expiresAt: tokenExpiry,
            idToken: idToken,
            issuer: oktaLink,
            scopes: ['openid', 'email'],
            value: idToken
        }
    }
};

function loadValidCred() {
    return 'Abc12345';
}

function mockedAuthTokenPwdChanged() {
    return '2019-09-25T09:28:03.000Z';
}

/** stores app config mock data */
export const CONFIG = {
    'environment': 'developer',
    'api': {
        'host': 'localhost',
        'port': '4000',
        'protocol': 'http',
        'base_url': '/api/'
    },
    'costModel': {
        'host': 'localhost',
        'port': '4000',
        'protocol': 'http',
        'base_url': '/api/'
    },
    'accessManagement': {
        'host': 'localhost',
        'port': '4000',
        'protocol': 'http',
        'base_url': ''
    },
    'operationalData': {
        'host': 'localhost',
        'port': '4000',
        'protocol': 'http',
        'base_url': ''
    },
    'integration': {
        'host': 'localhost',
        'port': '4000',
        'protocol': 'http',
        'base_url': ''
    },
    'vendor': {
        'host': 'localhost',
        'port': '4000',
        'protocol': 'http',
        'base_url': ''
    },
    'login': 'http://localhost:4201/',
    'logout': 'http://localhost:4201/#/logout?appId=2',
    'byPassLogin': 'true',
    'oktaToken': oktaToken,
    mockUser: { username: validEmail, password: loadValidCred() },
    validMockUser: { email: validEmail, password: loadValidCred() },
    mockedAuthToken: {
        transaction: {
            expiresAt: tokenExpiry,
            sessionToken: '20111fRbpZC4kQ3mTB6Rs_U5LZgWJ3QX9NXDZ9kHs44xoujdDfoN8ao',
            status: 'SUCCESS',
            user: {
                id: '00u1f0rihj9lzNjoz357',
                passwordChanged: mockedAuthTokenPwdChanged()
            },
            profile: {
                firstName: 'mohan',
                lastName: 'ashokan',
                locale: 'en',
                login: validEmail,
                timeZone: zoneTime
            },
            _links: { cancel: { href: hrefUrl, hints: { allow: ['POST'] } } }
        },
        tokens: validationToken
    },
    mockedAuthTokenLockedOut: {
        transaction: {
            expiresAt: tokenExpiry,
            sessionToken: '20111fRbpZC4kQ3mTB6Rs_U5LZgWJ3QX9NXDZ9kHs44xoujdDfoN8ao',
            status: 'LOCKED_OUT',
            user: {
                id: '00u1f0rihj9lzNjoz357',
                passwordChanged: mockedAuthTokenPwdChanged()
            },
            profile: {
                firstName: 'mohan',
                lastName: 'ashokan',
                locale: 'en',
                login: validEmail,
                timeZone: zoneTime
            },
            _links: { cancel: { href: hrefUrl, hints: { allow: ['POST'] } } }
        },
        tokens: validationToken
    },
    'loggerURL': 'http://ec2-52-87-247-160.compute-1.amazonaws.com:8080',
    'oktaClientId': '0oapycmz51G03uk3b0h7',
    'oktaUrl': 'https://loginpreview.cartus.com/oauth2/auspye2nuzSGJ0Kje0h7',
    "realogyOktaClientId": "0oa1s9l0geqx6CBg00h8",
    "realogyOktaUrl": "https://loginpreview-awre.cartus.com/oauth2/ausdtpyw647fbrcPi0h7",
    'oktaRedirectUri': 'http://localhost:4203',
    'URL_LOGSTASH': ''
};

/** mock candidate contact list */
export const candidateListMock: CandidateProfiles = {
    totalActiveCandidate: 11,
    candidates: [
        {
            candidateId: '1111',
            fullname: 'Maturity,Matthew',
            level: 'Level 2 ($75,000 - $150,000)',
            departure: {
                country: 'USA',
                streetAddress: null,
                city: 'Nutley',
                state: 'NJ',
                zipcode: null,
                fullAddress: 'NJ, Nutley'
            },
            destination: {
                country: 'USA',
                streetAddress: null,
                city: 'Houston',
                state: 'TX',
                zipcode: null,
                fullAddress: 'TX, Houston'
            },
            status: 'Invitation Not Sent',
            isAssessmentReceived: false,
            emailAddress: 'mathew.maturity@gmail.com',
            phoneNumber: '9089098908',
            businessUnit: 'Human Resources',
            invitationSentDate: '',
            createdDate: '21-JUN-19',
            createdBy: 'Matthew, Maturity',
            lastUpdatedDate: '21-JUN-19',
            noOfRooms: '4',
            housingType: 'Rents Apartment',
            noOfPeople: '3',
            countryDialingCode: '+1'
        },
        {
            candidateId: '2222',
            fullname: 'Beal, Christopher',
            level: 'Level 1 (Over $150,000)',
            departure: {
                country: 'USA',
                streetAddress: null,
                city: 'Atlanta',
                state: 'GA',
                zipcode: null,
                fullAddress: 'GA, Atlanta'
            },
            destination: {
                country: 'USA',
                streetAddress: null,
                city: 'Dallas',
                state: 'TX',
                zipcode: null,
                fullAddress: 'TX, Dallas'
            },
            status: 'Ready for Review',
            isAssessmentReceived: true,
            emailAddress: 'chris.beal@gmail.com',
            phoneNumber: '9089098908',
            businessUnit: 'Finance',
            invitationSentDate: '9-APR-2019',
            createdDate: '9-APR-19',
            createdBy: 'Matthew, Maturity',
            lastUpdatedDate: '2-MAY-19',
            noOfRooms: '4',
            housingType: 'Rents Apartment',
            noOfPeople: '3',
            countryDialingCode: '+1'
        },
        {
            candidateId: '3333',
            fullname: 'Goulet, Dan',
            level: 'Level 2 ($75,000 - $150,000)',
            departure: {
                country: 'USA',
                streetAddress: null,
                city: 'Atlanta',
                state: 'GA',
                zipcode: null,
                fullAddress: 'GA, Atlanta'
            },
            destination: {
                country: 'USA',
                streetAddress: null,
                city: 'Edison',
                state: 'NJ',
                zipcode: null,
                fullAddress: 'NJ, Edison'
            },
            status: 'Pending Van Line Quote',
            isAssessmentReceived: true,
            emailAddress: 'dan.goulet@gmail.com',
            phoneNumber: '9089098908',
            businessUnit: 'Engineering',
            invitationSentDate: '22-FEB-2019',
            createdDate: '2-FEB-19',
            createdBy: 'Matthew, Maturity',
            lastUpdatedDate: '23-MAR-19',
            noOfRooms: '8',
            housingType: 'Owns house',
            noOfPeople: '4',
            countryDialingCode: '+1'
        },
        {
            candidateId: '4444',
            fullname: 'Cordon, James',
            level: 'Level 3 (Below $75,000)',
            departure: {
                country: 'USA',
                streetAddress: null,
                city: 'Nutley',
                state: 'NJ',
                zipcode: null,
                fullAddress: 'NJ, Nutley'
            },
            destination: {
                country: 'USA',
                streetAddress: null,
                city: 'Dallas',
                state: 'TX',
                zipcode: null,
                fullAddress: 'TX, Dallas'
            },
            status: 'Ready for Review',
            isAssessmentReceived: false,
            emailAddress: 'j.cordon@gmail.com',
            phoneNumber: '9089098908',
            businessUnit: 'Engineering',
            invitationSentDate: '',
            createdDate: '23-JAN-19',
            createdBy: 'Matthew, Maturity',
            lastUpdatedDate: '30-JAN-19',
            noOfRooms: '4',
            housingType: 'Rents Apartment',
            noOfPeople: '2',
            countryDialingCode: '+1'
        },
        {
            candidateId: '5555',
            fullname: 'Hayes, Francesca',
            level: 'Level 1 (Over $150,000)',
            departure: {
                country: 'USA',
                streetAddress: null,
                city: 'Nutley',
                state: 'NJ',
                zipcode: null,
                fullAddress: 'NJ, Nutley'
            },
            destination: {
                country: 'USA',
                streetAddress: null,
                city: 'Dallas',
                state: 'TX',
                zipcode: null,
                fullAddress: 'TX, Dallas'
            },
            status: 'Pending Van Line Quote',
            isAssessmentReceived: true,
            emailAddress: 'francesca.hayes@gmail.com',
            phoneNumber: '9089098908',
            businessUnit: 'Accounting',
            invitationSentDate: '22-FEB-2019',
            createdDate: '2-FEB-19',
            createdBy: 'Matthew, Maturity',
            lastUpdatedDate: '23-MAR-19',
            noOfRooms: '6',
            housingType: 'Rents Apartment',
            noOfPeople: '1',
            countryDialingCode: '+1'
        },
        {
            candidateId: '6666',
            fullname: 'Hu, Adam',
            level: 'Level 2 ($75,000 - $150,000)',
            departure: {
                country: 'USA',
                streetAddress: null,
                city: 'Nutley',
                state: 'NJ',
                zipcode: null,
                fullAddress: 'NJ, Nutley'
            },
            destination: {
                country: 'USA',
                streetAddress: null,
                city: 'Dallas',
                state: 'TX',
                zipcode: null,
                fullAddress: 'TX, Dallas'
            },
            status: 'Invitation Not Sent',
            isAssessmentReceived: false,
            emailAddress: 'adam.hu@gmail.com',
            phoneNumber: '9089098908',
            businessUnit: 'Marketing',
            invitationSentDate: '',
            createdDate: '21-JUN-19',
            createdBy: 'Matthew, Maturity',
            lastUpdatedDate: '21-JUN-19',
            noOfRooms: '4',
            housingType: 'Rents Apartment',
            noOfPeople: '3',
            countryDialingCode: '+1'
        },
        {
            candidateId: '7777',
            fullname: 'Jones, Suehong',
            level: 'Level 2 ($75,000 - $150,000)',
            departure: {
                country: 'USA',
                streetAddress: null,
                city: 'Nutley',
                state: 'NJ',
                zipcode: null,
                fullAddress: 'NJ, Nutley'
            },
            destination: {
                country: 'USA',
                streetAddress: null,
                city: 'Dallas',
                state: 'TX',
                zipcode: null,
                fullAddress: 'TX, Dallas'
            },
            status: 'Invitation Not Sent',
            isAssessmentReceived: false,
            emailAddress: 'suehong.jones@gmail.com',
            phoneNumber: '9089098908',
            businessUnit: 'Human Resources',
            invitationSentDate: '',
            createdDate: '2-JUN-19',
            createdBy: 'Matthew, Maturity',
            lastUpdatedDate: '20-JUN-19',
            noOfRooms: '4',
            housingType: 'Rents Apartment',
            noOfPeople: '2',
            countryDialingCode: '+1'
        },
        {
            candidateId: '8888',
            fullname: 'Rector, Aleksandr',
            level: 'Level 2 ($75,000 - $150,000)',
            departure: {
                country: 'USA',
                streetAddress: null,
                city: 'Nutley',
                state: 'NJ',
                zipcode: null,
                fullAddress: 'NJ, Nutley'
            },
            destination: {
                country: 'USA',
                streetAddress: null,
                city: 'Dallas',
                state: 'TX',
                zipcode: null,
                fullAddress: 'TX, Dallas'
            },
            status: 'Invitation Not Sent',
            isAssessmentReceived: false,
            emailAddress: 'aleksandr.rector@gmail.com',
            phoneNumber: '9089098908',
            businessUnit: 'Product Solutions',
            invitationSentDate: '',
            createdDate: '21-JUN-19',
            createdBy: 'Matthew, Maturity',
            lastUpdatedDate: '21-JUN-19',
            noOfRooms: '4',
            housingType: 'Rents Apartment',
            noOfPeople: '3',
            countryDialingCode: '+1'
        },
        {
            candidateId: '9999',
            fullname: 'Richardson, Matthew',
            level: 'Level 2 ($75,000 - $150,000)',
            departure: {
                country: 'USA',
                streetAddress: null,
                city: 'Nutley',
                state: 'NJ',
                zipcode: null,
                fullAddress: 'NJ, Nutley'
            },
            destination: {
                country: 'USA',
                streetAddress: null,
                city: 'New York City',
                state: 'NY',
                zipcode: null,
                fullAddress: 'NY, New York City'
            },
            status: 'Invitation Not Sent',
            isAssessmentReceived: false,
            emailAddress: 'mathew.richardson@gmail.com',
            phoneNumber: '9089098908',
            businessUnit: 'Human Resources',
            invitationSentDate: '',
            createdDate: '21-JUN-19',
            createdBy: 'Matthew, Maturity',
            lastUpdatedDate: '21-JUN-19',
            noOfRooms: '4',
            housingType: 'Rents Apartment',
            noOfPeople: '2',
            countryDialingCode: '+1'
        },
        {
            candidateId: '1212',
            fullname: 'Varaha, Pritpal',
            level: 'Level 1 (Over $150,000)',
            departure: {
                country: 'USA',
                streetAddress: null,
                city: 'New York City',
                state: 'NY',
                zipcode: null,
                fullAddress: 'NY, New York City'
            },
            destination: {
                country: 'USA',
                streetAddress: null,
                city: 'Atlanta',
                state: 'GA',
                zipcode: null,
                fullAddress: 'GA, Atlanta'
            },
            status: 'Invitation Not Sent',
            isAssessmentReceived: false,
            emailAddress: 'pritpal.varaha@gmail.com',
            phoneNumber: '9089098908',
            businessUnit: 'Finance',
            invitationSentDate: '',
            createdDate: '21-JUN-19',
            createdBy: 'Matthew, Maturity',
            lastUpdatedDate: '21-JUN-19',
            noOfRooms: '4',
            housingType: 'Rents Apartment',
            noOfPeople: '4',
            countryDialingCode: '+1'
        },
        {
            candidateId: '1313',
            fullname: 'Ellacott, Robin',
            level: 'Level 3 (Below $75,000)',
            departure: {
                country: 'USA',
                streetAddress: null,
                city: 'Dallas',
                state: 'TX',
                zipcode: null,
                fullAddress: 'TX, Dallas'
            },
            destination: {
                country: 'USA',
                streetAddress: null,
                city: 'Fushing',
                state: 'NY',
                zipcode: null,
                fullAddress: 'NY, Fushing'
            },
            status: 'Invitation Not Sent',
            isAssessmentReceived: false,
            emailAddress: 'robin.ellacott@gmail.com',
            phoneNumber: '9089098908',
            businessUnit: 'Engineering',
            invitationSentDate: '',
            createdDate: '21-JUN-19',
            createdBy: 'Matthew, Maturity',
            lastUpdatedDate: '21-JUN-19',
            noOfRooms: '4',
            housingType: 'Rents Apartment',
            noOfPeople: '4',
            countryDialingCode: '+1'
        }
    ]
};

/**candidate list mock with status */
export const candidatesDetailsMock204 = {
    status: 204,
    statusText: 'OK',
    url: 'https://alphaapitst01.cartus.com/candidate?clientContactId=5de2cd7d65236577b9223541&sortField=fullname&sortDir=ASC&limit=20',
    ok: true,
    type: 4,
    body: {
        totalActiveCandidate: 11,
        candidates: [
            {
                candidateId: '1111',
                fullname: 'Maturity,Matthew',
                level: 'Level 2 ($75,000 - $150,000)',
                departure: {
                    country: 'USA',
                    streetAddress: null,
                    city: 'Nutley',
                    state: 'NJ',
                    zipcode: null,
                    fullAddress: 'NJ, Nutley'
                },
                destination: {
                    country: 'USA',
                    streetAddress: null,
                    city: 'Houston',
                    state: 'TX',
                    zipcode: null,
                    fullAddress: 'TX, Houston'
                },
                status: 'Invitation Not Sent',
                isAssessmentReceived: false,
                emailAddress: 'mathew.maturity@gmail.com',
                phoneNumber: '9089098908',
                businessUnit: 'Human Resources',
                invitationSentDate: '',
                createdDate: '21-JUN-19',
                createdBy: 'Matthew, Maturity',
                lastUpdatedDate: '21-JUN-19',
                noOfRooms: '4',
                housingType: 'Rents Apartment',
                noOfPeople: '3'
            },
            {
                candidateId: '2222',
                fullname: 'Beal, Christopher',
                level: 'Level 1 (Over $150,000)',
                departure: {
                    country: 'USA',
                    streetAddress: null,
                    city: 'Atlanta',
                    state: 'GA',
                    zipcode: null,
                    fullAddress: 'GA, Atlanta'
                },
                destination: {
                    country: 'USA',
                    streetAddress: null,
                    city: 'Dallas',
                    state: 'TX',
                    zipcode: null,
                    fullAddress: 'TX, Dallas'
                },
                status: 'Ready for Review',
                isAssessmentReceived: true,
                emailAddress: 'chris.beal@gmail.com',
                phoneNumber: '9089098908',
                businessUnit: 'Finance',
                invitationSentDate: '9-APR-2019',
                createdDate: '9-APR-19',
                createdBy: 'Matthew, Maturity',
                lastUpdatedDate: '2-MAY-19',
                noOfRooms: '4',
                housingType: 'Rents Apartment',
                noOfPeople: '3'
            },
            {
                candidateId: '3333',
                fullname: 'Goulet, Dan',
                level: 'Level 2 ($75,000 - $150,000)',
                departure: {
                    country: 'USA',
                    streetAddress: null,
                    city: 'Atlanta',
                    state: 'GA',
                    zipcode: null,
                    fullAddress: 'GA, Atlanta'
                },
                destination: {
                    country: 'USA',
                    streetAddress: null,
                    city: 'Edison',
                    state: 'NJ',
                    zipcode: null,
                    fullAddress: 'NJ, Edison'
                },
                status: 'Pending Van Line Quote',
                isAssessmentReceived: true,
                emailAddress: 'dan.goulet@gmail.com',
                phoneNumber: '9089098908',
                businessUnit: 'Engineering',
                invitationSentDate: '22-FEB-2019',
                createdDate: '2-FEB-19',
                createdBy: 'Matthew, Maturity',
                lastUpdatedDate: '23-MAR-19',
                noOfRooms: '8',
                housingType: 'Owns house',
                noOfPeople: '4'
            },
            {
                candidateId: '4444',
                fullname: 'Cordon, James',
                level: 'Level 3 (Below $75,000)',
                departure: {
                    country: 'USA',
                    streetAddress: null,
                    city: 'Nutley',
                    state: 'NJ',
                    zipcode: null,
                    fullAddress: 'NJ, Nutley'
                },
                destination: {
                    country: 'USA',
                    streetAddress: null,
                    city: 'Dallas',
                    state: 'TX',
                    zipcode: null,
                    fullAddress: 'TX, Dallas'
                },
                status: 'Ready for Review',
                isAssessmentReceived: false,
                emailAddress: 'j.cordon@gmail.com',
                phoneNumber: '9089098908',
                businessUnit: 'Engineering',
                invitationSentDate: '',
                createdDate: '23-JAN-19',
                createdBy: 'Matthew, Maturity',
                lastUpdatedDate: '30-JAN-19',
                noOfRooms: '4',
                housingType: 'Rents Apartment',
                noOfPeople: '2'
            },
            {
                candidateId: '5555',
                fullname: 'Hayes, Francesca',
                level: 'Level 1 (Over $150,000)',
                departure: {
                    country: 'USA',
                    streetAddress: null,
                    city: 'Nutley',
                    state: 'NJ',
                    zipcode: null,
                    fullAddress: 'NJ, Nutley'
                },
                destination: {
                    country: 'USA',
                    streetAddress: null,
                    city: 'Dallas',
                    state: 'TX',
                    zipcode: null,
                    fullAddress: 'TX, Dallas'
                },
                status: 'Pending Van Line Quote',
                isAssessmentReceived: true,
                emailAddress: 'francesca.hayes@gmail.com',
                phoneNumber: '9089098908',
                businessUnit: 'Accounting',
                invitationSentDate: '22-FEB-2019',
                createdDate: '2-FEB-19',
                createdBy: 'Matthew, Maturity',
                lastUpdatedDate: '23-MAR-19',
                noOfRooms: '6',
                housingType: 'Rents Apartment',
                noOfPeople: '1'
            },
            {
                candidateId: '6666',
                fullname: 'Hu, Adam',
                level: 'Level 2 ($75,000 - $150,000)',
                departure: {
                    country: 'USA',
                    streetAddress: null,
                    city: 'Nutley',
                    state: 'NJ',
                    zipcode: null,
                    fullAddress: 'NJ, Nutley'
                },
                destination: {
                    country: 'USA',
                    streetAddress: null,
                    city: 'Dallas',
                    state: 'TX',
                    zipcode: null,
                    fullAddress: 'TX, Dallas'
                },
                status: 'Invitation Not Sent',
                isAssessmentReceived: false,
                emailAddress: 'adam.hu@gmail.com',
                phoneNumber: '9089098908',
                businessUnit: 'Marketing',
                invitationSentDate: '',
                createdDate: '21-JUN-19',
                createdBy: 'Matthew, Maturity',
                lastUpdatedDate: '21-JUN-19',
                noOfRooms: '4',
                housingType: 'Rents Apartment',
                noOfPeople: '3'
            },
            {
                candidateId: '7777',
                fullname: 'Jones, Suehong',
                level: 'Level 2 ($75,000 - $150,000)',
                departure: {
                    country: 'USA',
                    streetAddress: null,
                    city: 'Nutley',
                    state: 'NJ',
                    zipcode: null,
                    fullAddress: 'NJ, Nutley'
                },
                destination: {
                    country: 'USA',
                    streetAddress: null,
                    city: 'Dallas',
                    state: 'TX',
                    zipcode: null,
                    fullAddress: 'TX, Dallas'
                },
                status: 'Invitation Not Sent',
                isAssessmentReceived: false,
                emailAddress: 'suehong.jones@gmail.com',
                phoneNumber: '9089098908',
                businessUnit: 'Human Resources',
                invitationSentDate: '',
                createdDate: '2-JUN-19',
                createdBy: 'Matthew, Maturity',
                lastUpdatedDate: '20-JUN-19',
                noOfRooms: '4',
                housingType: 'Rents Apartment',
                noOfPeople: '2'
            },
            {
                candidateId: '8888',
                fullname: 'Rector, Aleksandr',
                level: 'Level 2 ($75,000 - $150,000)',
                departure: {
                    country: 'USA',
                    streetAddress: null,
                    city: 'Nutley',
                    state: 'NJ',
                    zipcode: null,
                    fullAddress: 'NJ, Nutley'
                },
                destination: {
                    country: 'USA',
                    streetAddress: null,
                    city: 'Dallas',
                    state: 'TX',
                    zipcode: null,
                    fullAddress: 'TX, Dallas'
                },
                status: 'Invitation Not Sent',
                isAssessmentReceived: false,
                emailAddress: 'aleksandr.rector@gmail.com',
                phoneNumber: '9089098908',
                businessUnit: 'Product Solutions',
                invitationSentDate: '',
                createdDate: '21-JUN-19',
                createdBy: 'Matthew, Maturity',
                lastUpdatedDate: '21-JUN-19',
                noOfRooms: '4',
                housingType: 'Rents Apartment',
                noOfPeople: '3'
            },
            {
                candidateId: '9999',
                fullname: 'Richardson, Matthew',
                level: 'Level 2 ($75,000 - $150,000)',
                departure: {
                    country: 'USA',
                    streetAddress: null,
                    city: 'Nutley',
                    state: 'NJ',
                    zipcode: null,
                    fullAddress: 'NJ, Nutley'
                },
                destination: {
                    country: 'USA',
                    streetAddress: null,
                    city: 'New York City',
                    state: 'NY',
                    zipcode: null,
                    fullAddress: 'NY, New York City'
                },
                status: 'Invitation Not Sent',
                isAssessmentReceived: false,
                emailAddress: 'mathew.richardson@gmail.com',
                phoneNumber: '9089098908',
                businessUnit: 'Human Resources',
                invitationSentDate: '',
                createdDate: '21-JUN-19',
                createdBy: 'Matthew, Maturity',
                lastUpdatedDate: '21-JUN-19',
                noOfRooms: '4',
                housingType: 'Rents Apartment',
                noOfPeople: '2'
            },
            {
                candidateId: '1212',
                fullname: 'Varaha, Pritpal',
                level: 'Level 1 (Over $150,000)',
                departure: {
                    country: 'USA',
                    streetAddress: null,
                    city: 'New York City',
                    state: 'NY',
                    zipcode: null,
                    fullAddress: 'NY, New York City'
                },
                destination: {
                    country: 'USA',
                    streetAddress: null,
                    city: 'Atlanta',
                    state: 'GA',
                    zipcode: null,
                    fullAddress: 'GA, Atlanta'
                },
                status: 'Invitation Not Sent',
                isAssessmentReceived: false,
                emailAddress: 'pritpal.varaha@gmail.com',
                phoneNumber: '9089098908',
                businessUnit: 'Finance',
                invitationSentDate: '',
                createdDate: '21-JUN-19',
                createdBy: 'Matthew, Maturity',
                lastUpdatedDate: '21-JUN-19',
                noOfRooms: '4',
                housingType: 'Rents Apartment',
                noOfPeople: '4'
            },
            {
                candidateId: '1313',
                fullname: 'Ellacott, Robin',
                level: 'Level 3 (Below $75,000)',
                departure: {
                    country: 'USA',
                    streetAddress: null,
                    city: 'Dallas',
                    state: 'TX',
                    zipcode: null,
                    fullAddress: 'TX, Dallas'
                },
                destination: {
                    country: 'USA',
                    streetAddress: null,
                    city: 'Fushing',
                    state: 'NY',
                    zipcode: null,
                    fullAddress: 'NY, Fushing'
                },
                status: 'Invitation Not Sent',
                isAssessmentReceived: false,
                emailAddress: 'robin.ellacott@gmail.com',
                phoneNumber: '9089098908',
                businessUnit: 'Engineering',
                invitationSentDate: '',
                createdDate: '21-JUN-19',
                createdBy: 'Matthew, Maturity',
                lastUpdatedDate: '21-JUN-19',
                noOfRooms: '4',
                housingType: 'Rents Apartment',
                noOfPeople: '4'
            }
        ]
    }
};

/** mock candidate contact info */
export const candidateContactInfoMock: CandidateContactInfo = {
    candidateName: 'Maturity,Matthew',
    phoneNumber: 9876543210,
    emailId: 'mathew.maturity@gmail.com'
};

/** stores candidate mock data */
export const candidateMock: Candidate = {
    candidateId: '2222',
    fullname: 'Beal, Christopher',
    level: 'Level 1 (Over $150,000)',
    departure: {
        country: 'USA',
        streetAddress: null,
        city: 'Atlanta',
        state: 'GA',
        zipcode: null,
        fullAddress: 'GA, Atlanta'
    },
    destination: {
        country: 'USA',
        streetAddress: null,
        city: 'Dallas',
        state: 'TX',
        zipcode: null,
        fullAddress: 'TX, Dallas'
    },
    status: 'Ready for Review',
    isAssessmentReceived: true,
    emailAddress: 'chris.beal@gmail.com',
    phoneNumber: '9089098908',
    businessUnit: 'Finance',
    invitationSentDate: '9-APR-2019',
    createdDate: '9-APR-19',
    createdBy: 'Matthew, Maturity',
    lastUpdatedDate: '2-MAY-19',
    noOfRooms: '4',
    housingType: 'Rents Apartment',
    noOfPeople: '3',
    executedFunctionType: 'Update',
    countryDialingCode: '+1'
};



/** stores mock city list */
export const cityListMock: string[] = ['Newyork', 'Washington', 'Danbury, CT'];

/**stores duplicate API response */
export const isDuplicateResonseMock: APIResponseDuplicate = {
    duplicateStatus: true,
    description: 'Duplicate candidate found',
    CODE: 'DUP_FOUND'
};

/** stores mock response for success response */
export const mockedSuccessResponse = {
    candidateId: '1111',
    message: 'Success',
    statusCode: 200
};

/** stores mock response for create candidate */
export const mockedAddedUserResponse = {
    candidateId: '1111',
    message: 'Added user Successfully',
    statusCode: 200
};

/** stores mock response for update candidate */
export const mockedUpdatedUserResponse = {
    candidateId: '1111',
    message: 'Updated user Successfully',
    statusCode: 200
};

/** stores mock response for adding candidate */
export const mockedResponse: APIResponse = {
    candidateID: '1234',
    message: 'Added user Successfully',
    statusCode: 200
};

/** stores candidate info */
export const candidateData: any = {
    candidateId: '1111',
    fullname: 'Maturity,Matthew',
    level: 'Level 2 ($75,000 - $150,000)',
    departure: {
        country: 'USA',
        streetAddress: null,
        city: 'Nutley',
        state: 'NJ',
        zipcode: null,
        fullAddress: 'NJ, Nutley'
    },
    destination: {
        country: 'USA',
        streetAddress: null,
        city: 'Austin',
        state: 'TX',
        zipcode: null,
        fullAddress: 'TX, Austin'
    },
    status: 'Invitation Not Sent',
    isAssessmentReceived: false,
    emailAddress: 'mathew.maturity@gmail.com',
    businessUnit: 'Human Resources',
    invitationSentDate: '',
    createdDate: '21-JUN-19',
    createdBy: 'Matthew, Maturity',
    lastUpdatedDate: '21-JUN-19',
    streetAddress: '41 Apple Ridge Rd',
    city: 'Danbury',
    state: 'CT',
    zipcode: '06810',
    noOfRooms: '4',
    housingType: 'Rents Apartment',
    noOfPeople: '3',
    total: '5',
    countryDialingCode: '7238'
};

/** stores mock for dialog */
export const dialogMock = {
    close: () => { },
    open: () => { },
    afterClosed: () => { }
};

/** stores mock address */
export const addressMock: Address = {
    fullAddress: 'New Jersey, NJ, USA',
    streetAddress: '',
    city: 'New Jersey',
    state: 'NJ',
    country: 'USA',
    zipcode: null
};

/** stores mock API URL */
export const API_ROOT_URL_MOCK = 'http://localhost';

/** stores mock client id */
export const clientContactIDMock = '5d8b16401c9d440000f9bdec';

/** Cartus employees mock  */
export const cartusEmployeesMock = {
    employees: [
        {
            employeeId: '5db7e1f637c7c8041cbb9482',
            name: 'Zhao Qing',
            emailAddress: 'cristalle.szeto@cartus.com',
            roles: [
                {
                    key: 'cartus-account-manager',
                    roleName: 'Account Manager',
                    status: true
                },
                {
                    key: 'cartus-ccc-qa',
                    roleName: 'CCC QA',
                    status: false
                },
                {
                    key: 'cartus-ccc-specialist',
                    roleName: 'CCC Specialist',
                    status: false
                },
                {
                    key: 'cartus-account-auditor',
                    roleName: 'Operations Account Auditor',
                    status: false
                },
                {
                    key: 'cartus-rewards-coordinator',
                    roleName: 'Rewards Coordinator',
                    status: false
                }
            ]
        },
        {
            employeeId: '5db7e1f637c7c8041cbb9483',
            name: 'Maturity Mathew',
            emailAddress: 'maturity@cartus.com',
            roles: [
                {
                    key: 'cartus-account-manager',
                    roleName: 'Account Manager',
                    status: true
                },
                {
                    key: 'cartus-ccc-qa',
                    roleName: 'CCC QA',
                    status: true
                },
                {
                    key: 'cartus-ccc-specialist',
                    roleName: 'CCC Specialist',
                    status: false
                },
                {
                    key: 'cartus-account-auditor',
                    roleName: 'Operations Account Auditor',
                    status: false
                },
                {
                    key: 'cartus-rewards-coordinator',
                    roleName: 'Rewards Coordinator',
                    status: false
                }
            ]
        },
        {
            employeeId: '5db7e1f637c7c8041cbb9484',
            name: 'John Joseph',
            emailAddress: 'john.joseph@cartus.com',
            roles: [
                {
                    key: 'cartus-account-manager',
                    roleName: 'Account Manager',
                    status: false
                },
                {
                    key: 'cartus-ccc-qa',
                    roleName: 'CCC QA',
                    status: false
                },
                {
                    key: 'cartus-ccc-specialist',
                    roleName: 'CCC Specialist',
                    status: false
                },
                {
                    key: 'cartus-account-auditor',
                    roleName: 'Operations Account Auditor',
                    status: false
                },
                {
                    key: 'cartus-rewards-coordinator',
                    roleName: 'Rewards Coordinator',
                    status: false
                }
            ]
        },
        {
            employeeId: '5db7e1f637c7c8041cbb9485',
            name: 'Priya Warrier',
            emailAddress: 'priya.warrier@cartus.com',
            roles: [
                {
                    key: 'cartus-account-manager',
                    roleName: 'Account Manager',
                    status: true
                },
                {
                    key: 'cartus-ccc-qa',
                    roleName: 'CCC QA',
                    status: true
                },
                {
                    key: 'cartus-ccc-specialist',
                    roleName: 'CCC Specialist',
                    status: true
                },
                {
                    key: 'cartus-account-auditor',
                    roleName: 'Operations Account Auditor',
                    status: false
                },
                {
                    key: 'cartus-rewards-coordinator',
                    roleName: 'Rewards Coordinator',
                    status: false
                }
            ]
        },
        {
            employeeId: '5db7e1f637c7c8041cbb9486',
            name: 'Elon Musk',
            emailAddress: 'elon.musk@cartus.com',
            roles: [
                {
                    key: 'cartus-account-manager',
                    roleName: 'Account Manager',
                    status: true
                },
                {
                    key: 'cartus-ccc-qa',
                    roleName: 'CCC QA',
                    status: true
                },
                {
                    key: 'cartus-ccc-specialist',
                    roleName: 'CCC Specialist',
                    status: true
                },
                {
                    key: 'cartus-account-auditor',
                    roleName: 'Operations Account Auditor',
                    status: true
                },
                {
                    key: 'cartus-rewards-coordinator',
                    roleName: 'Rewards Coordinator',
                    status: true
                }
            ]
        },
        {
            employeeId: '5db7e1f637c7c8041cbb9487',
            name: 'Sundar Pichai',
            emailAddress: 'sundar.pichai@cartus.com',
            roles: [
                {
                    key: 'cartus-account-manager',
                    roleName: 'Account Manager',
                    status: true
                },
                {
                    key: 'cartus-ccc-qa',
                    roleName: 'CCC QA',
                    status: false
                },
                {
                    key: 'cartus-ccc-specialist',
                    roleName: 'CCC Specialist',
                    status: true
                },
                {
                    key: 'cartus-account-auditor',
                    roleName: 'Operations Account Auditor',
                    status: false
                },
                {
                    key: 'cartus-rewards-coordinator',
                    roleName: 'Rewards Coordinator',
                    status: false
                }
            ]
        }
    ],
    totalEmployee: 6
};

/** Response Mock when Roles are updated */
export const cartusEmployeeRoleUpdateResponseMock: APIResponse = {
    statusCode: 200,
    description: 'Updated Employee Roles Successfully'
};

/** employee mock to send in dialog */
export const employeeMock = {
    employeeId: '5de2dc7a65236577b92d18fd',
    roles: [
        {
            key: 'cartus-account-manager',
            roleName: 'Account Manager',
            status: false
        },
        {
            key: 'cartus-ccc-qa',
            roleName: 'CCC QA',
            status: false
        },
        {
            key: 'cartus-ccc-specialist',
            roleName: 'CCC Specialist',
            status: false
        },
        {
            key: 'cartus-account-auditor',
            roleName: 'Operations Account Auditor',
            status: false
        },
        {
            key: 'cartus-rewards-coordinator',
            roleName: 'Rewards Coordinator',
            status: false
        },
        {
            key: 'cartus-security-administrator',
            roleName: 'Security Administrator',
            status: false
        }
    ]
};

/**employee details mock */
export const employeeDetailsMock = {
    status: 200,
    statusText: 'OK',
    url: 'https://alphaapitst01.cartus.com/client/employeeDetails?sortField=name&sortDir=ASC&limit=20',
    ok: true,
    type: 4,
    body: {
        totalEmployees: 2,
        employees: [
            {
                clientId: '5dcf100073f701819e97d883',
                employeeId: '5de2dc7a65236577b92d18fd',
                name: 'CCCSpecialist2FN, CCCSpecialist2LN',
                emailAddress: 'cccspecialist2@cartus.com',
                roles: [
                    {
                        key: 'cartus-account-manager',
                        roleName: 'Account Manager',
                        status: true
                    },
                    {
                        key: 'cartus-security-administrator',
                        roleName: 'Security Administrator',
                        status: true
                    }
                ]
            },
            {
                clientId: '5dcf100073f701819e97d883',
                employeeId: '5de2dbce65236577b92c81cb',
                name: 'CCCSpecialist1FN, CCCSpecialist1LN',
                emailAddress: 'cccspecialist1@cartus.com',
                roles: [
                    {
                        key: 'cartus-account-manager',
                        roleName: 'Account Manager',
                        status: true
                    },
                    {
                        key: 'cartus-security-administrator',
                        roleName: 'Security Administrator',
                        status: true
                    }
                ]
            }
        ]
    }
};

/** employee details Service Mock */
export const EmployeeDetailsServiceMock = {
    getEmployeeDetailsQueryString: 'clientContactId=5de2cd7d65236577b9223541&sortField=fullname&sortDir=ASC&limit=20'
};

/**role capability mock 1 */
export const roleCapabilityMock1 = {
    partyRoles: [
        {
            name: 'client-contact-administrator',
            associatedParty: '5de08d19a8554a2266114ff6',
            fromDate: '2019-11-29T12:57:12.448Z'
        },
        {
            name: 'client-contact',
            associatedParty: '5de08d19a8554a2266114ff6',
            fromDate: '2019-11-29T12:57:12.448Z'
        }
    ],
    roleCapabilities: [
        {
            name: 'Self Registration',
            operation: 'write',
            permission: 'allow'
        },
        {
            name: 'Cost Models All',
            operation: 'write',
            permission: 'allow'
        },
        {
            name: 'Manage Candidates/Transferees All',
            operation: 'write',
            permission: 'allow'
        },
        {
            name: 'View Destination',
            operation: 'write',
            permission: 'allow'
        }
    ]
};

export const candidateProfileServiceMock = {
    partyId: '5de2cd7d65236577b9223541',
    getCandidateProfilesQueryString: 'clientContactId=5de2cd7d65236577b9223541&sortField=fullname&sortDir=ASC&limit=20',
    roleCapabilities: {
        partyRoles: [{
            name: 'candidate',
            associatedParty: '5d8b16401c9d440000f9bdec',
            fromDate: '2019-11-13T06:33:28.918Z',
            toDate: '2019-11-13T06:33:40.733Z'
        }, {
            name: 'transferee',
            associatedParty: '5d8b16401c9d440000f9bdec',
            fromDate: '2019-11-13T06:33:48.436Z'
        }],
        roleCapabilities: [{
            roleName: 'candidate',
            capabilities: [{
                name: 'Alpha Client Portal',
                operation: 'read',
                permission: 'allow'
            }]
        }]
    }
};

/** mock data for account manager details */
export const accountManagerMock: any = {
    status: 200,
    body: {
        totalEmployees: 12,
        employees: [
            {
                employeeId: '59482',
                name: 'Zhao Qing',
                emailAddress: 'cristalle.szeto@cartus.com',
                clientList: [
                    {
                        clientNumber: 'C0220',
                        entityName: 'adasdsa',
                        clientId: '5e0dcfe476f4970cfb46d119'
                    }
                ]
            },
            {
                employeeId: '5db483',
                name: 'Maturity Mathew',
                emailAddress: 'maturity@cartus.com',
                clientList: [
                    {
                        clientNumber: '6152',
                        entityName: 'Citibank, CAN',
                        clientId: '5dgysew72864rbuyfqb76413'

                    },
                    {
                        clientNumber: '4321',
                        entityName: 'Orland Bank',
                        clientId: '5great653fd4rbuyfqb76413'
                    }
                ]
            },
            {
                employeeId: '5db7e1',
                name: 'John Joseph',
                emailAddress: 'john.joseph@cartus.com',
                clientList: [
                    {
                        clientNumber: '6488',
                        entityName: 'Advance America, Cash Advance Centers, Inc.',
                        clientId: '5dcf15f773f70101bd97e0b3'
                    }
                ]
            },
            {
                employeeId: '5d485',
                name: 'Priya Warrier',
                emailAddress: 'priya.warrier@cartus.com',
                clientList: []
            },
            {
                employeeId: '5db76',
                name: 'Elon Musk',
                emailAddress: 'elon.musk@cartus.com',
                clientList: []
            },
            {
                employeeId: '5db87',
                name: 'Sundar Pichai',
                emailAddress: 'sundar.pichai@cartus.com',
                clientList: []
            }
        ]
    }
};

export const accountMAnagerClients: AccountManagerClients[] = [{
    clientNumber: '6152',
    entityName: 'Citibank, CAN',
    clientId: '5dgysew72864rbuyfqb76413'

},
{
    clientNumber: '6210',
    entityName: 'Citibank Ireland',
    clientId: '5dgysew72864rbuy6qed76413'
},
{
    clientNumber: '6126',
    entityName: 'Citibank, N.A.',
    clientId: '5edgyh653fd4rbuyfqb76413'
},
{
    clientNumber: '6132',
    entityName: 'Citibank, A.R.',
    clientId: '5edgyh65logicalyfqb76413'
},
{
    clientNumber: '4562',
    entityName: 'Bank',
    clientId: '5edpolygamysrbuyfqb76413'
},
{
    clientNumber: '9876',
    entityName: 'Bank Danbury',
    clientId: '5edgyh653lolrbuyfqb76413'
},
{
    clientNumber: '6789',
    entityName: 'Citi CT',
    clientId: '5edgyh653fd4rbuyfuhuckit'
},
{
    clientNumber: '4321',
    entityName: 'Orland Bank',
    clientId: '5great653fd4rbuyfqb76413'
}
];

/** mock request body for fetchClients call */
export const fetchClientsMockRequest: any = {
    'searchText': 'abc',
    'existingClients': [
        {
            '_id': 'qwertyuio2345678'
        },
        {
            '_id': 'asdfghjkl324567'
        }
    ]
};

export const fetchUserProfileMock: any = {
    'clientId': '5d8b15c81c9d440000f9bdea',
    'clientName': 'Mindtree Ltd.',
    'emailAddress': 'ruben.nikson@mobilitydba.com',
    'phoneNumber': '+13124659860',
    'roleId': '5dd362231c9d440000acf6a4',
    'roleName': 'client-contact',
    'userId': '5d8b16401c9d440000f9bdec',
    'userName': 'arjun chandra'
};

/** mock request body for save data call */
export const saveDataMockRequest: any = {
    'employeeId': '5de2dc7a65236577b92d18fd',
    'clientIds': ['5dcf100073f701819e97d883', '5dcf100073f701819e97d883']
};

/**states mock data */
export const stateList = [
    { 'name': 'Alabama', 'shortName': 'AL' },
    { 'name': 'Alaska', 'shortName': 'AK' },
    { 'name': 'Arizona', 'shortName': 'AZ' },
    { 'name': 'Arkansas', 'shortName': 'AR' },
    { 'name': 'California', 'shortName': 'CA' },
    { 'name': 'Colorado', 'shortName': 'CO' },
    { 'name': 'Connecticut', 'shortName': 'CT' },
    { 'name': 'Delaware', 'shortName': 'DE' },
    { 'name': 'District of Columbia', 'shortName': 'DC' },
    { 'name': 'Florida', 'shortName': 'FL' },
    { 'name': 'Georgia', 'shortName': 'GA' },
    { 'name': 'Hawaii', 'shortName': 'HI' },
    { 'name': 'Idaho', 'shortName': 'ID' },
    { 'name': 'Illinois', 'shortName': 'IL' },
    { 'name': 'Indiana', 'shortName': 'IN' },
    { 'name': 'Iowa', 'shortName': 'IA' },
    { 'name': 'Kansa', 'shortName': 'KS' },
    { 'name': 'Kentucky', 'shortName': 'KY' },
    { 'name': 'Lousiana', 'shortName': 'LA' },
    { 'name': 'Maine', 'shortName': 'ME' },
    { 'name': 'Maryland', 'shortName': 'MD' },
    { 'name': 'Massachusetts', 'shortName': 'MA' },
    { 'name': 'Michigan', 'shortName': 'MI' },
    { 'name': 'Minnesota', 'shortName': 'MN' },
    { 'name': 'Mississippi', 'shortName': 'MS' },
    { 'name': 'Missouri', 'shortName': 'MO' },
    { 'name': 'Montana', 'shortName': 'MT' },
    { 'name': 'Nebraska', 'shortName': 'NE' },
    { 'name': 'Nevada', 'shortName': 'NV' },
    { 'name': 'New Hampshire', 'shortName': 'NH' },
    { 'name': 'New Jersey', 'shortName': 'NJ' },
    { 'name': 'New Mexico', 'shortName': 'NM' },
    { 'name': 'New York', 'shortName': 'NY' },
    { 'name': 'North Carolina', 'shortName': 'NC' },
    { 'name': 'North Dakota', 'shortName': 'ND' },
    { 'name': 'Ohio', 'shortName': 'OH' },
    { 'name': 'Oklahoma', 'shortName': 'OK' },
    { 'name': 'Oregon', 'shortName': 'OR' },
    { 'name': 'Pennsylvania', 'shortName': 'PA' },
    { 'name': 'Rhode Island', 'shortName': 'RI' },
    { 'name': 'South Carolina', 'shortName': 'SC' },
    { 'name': 'South Dakota', 'shortName': 'SD' },
    { 'name': 'Tennessee', 'shortName': 'TN' },
    { 'name': 'Texas', 'shortName': 'TX' },
    { 'name': 'Utah', 'shortName': 'UT' },
    { 'name': 'Vermont', 'shortName': 'VT' },
    { 'name': 'Virginia', 'shortName': 'VA' },
    { 'name': 'Washington', 'shortName': 'WA' },
    { 'name': 'West Virginia', 'shortName': 'WV' },
    { 'name': 'Wisconsin', 'shortName': 'WI' },
    { 'name': 'Wyoming', 'shortName': 'WY' }
];

export const extension: Extension[] = [
    {
        'countryDialingCode': '1',
        'name': 'United States'
    },
    {
        'countryDialingCode': '93',
        'name': 'Afghanistan'
    },
    {
        'countryDialingCode': '684',
        'name': 'American Samoa'
    },
    {
        'countryDialingCode': '376',
        'name': 'Andorra'
    },
    {
        'countryDialingCode': '973',
        'name': 'Bahrain'
    },
    {
        'countryDialingCode': '880',
        'name': 'Bangladesh'
    },
    {
        'countryDialingCode': '246',
        'name': 'Barbados'
    },
    {
        'countryDialingCode': '375',
        'name': 'Belarus'
    },
    {
        'countryDialingCode': '32',
        'name': 'Belgium'
    },
    {
        'countryDialingCode': '501',
        'name': 'Belize'
    },
    {
        'countryDialingCode': '284',
        'name': 'Bouvet Island'
    }
];

export const clientContacts: ClientContact[] = [
    {
        clientContactID: '5e330230969cd70009fc04f9',
        clientID: '5df30bd69389d93a5d575cc1',
        firstName: 'Kasim',
        lastName: 'Nadim',
        emailAddress: 'test@test.com',
        phoneNumber: '2032050000',
        status: 'Invitation Not Sent',
        role: [],
        isBillingContact: false,
        invitedAsClientContact: false,
        statusDate: '2020-02-12',
        isDeleted: false,
        countryDialingCode: '+1'
    },
    {
        clientContactID: '5e330230969cd70009fc04f9',
        clientID: '5df30bd69389d93a5d575cc1',
        firstName: 'Kasim',
        lastName: 'Nadim',
        emailAddress: 'test@test.com',
        phoneNumber: '2032050000',
        status: 'Invitation Not Sent',
        role: [],
        isBillingContact: false,
        invitedAsClientContact: false,
        statusDate: '2020-02-12',
        isDeleted: false,
        countryDialingCode: '+44'
    },
    {
        clientContactID: '5e330230969cd70009fc04f9',
        clientID: '5df30bd69389d93a5d575cc1',
        firstName: 'Kasim',
        lastName: 'Nadim',
        emailAddress: 'test@test.com',
        phoneNumber: '2032050000',
        status: 'Invitation Not Sent',
        role: [],
        isBillingContact: false,
        invitedAsClientContact: false,
        statusDate: '2020-02-12',
        isDeleted: false,
        countryDialingCode: '+91'
    }
];

/**extension mock */
export const extensionMock: any = [
    {
        currency: {
            description: 'US Dollar',
            code: 'USD'
        },
        _id: '5e4471410cdc3a1d50c81db9',
        dialingCode: 1,
        iso3CharCode: 'USA',
        iso2CharCode: 'US',
        name: 'United States'
    },
    {
        currency: {
            description: 'Russian Ruble',
            code: 'RUB'
        },
        _id: '5e4471410cdc3a1d50c81d89',
        dialingCode: 7,
        iso3CharCode: 'RUS',
        iso2CharCode: 'RU',
        name: 'Russia'
    },
    {
        currency: {
            description: 'Egyptian Pound',
            code: 'EGP'
        },
        _id: '5e4471410cdc3a1d50c81d17',
        dialingCode: 20,
        iso3CharCode: 'EGY',
        iso2CharCode: 'EG',
        name: 'Egypt'
    },
    {
        currency: {
            description: 'Canadian Dollar',
            code: 'CAD'
        },
        _id: '613f05a4fe21242c58e39c7f',
        dialingCode: 1,
        iso3CharCode: 'CAN',
        iso2CharCode: 'CA',
        name: 'Canada',
    }
];

/** Consultant Details mock */
export const consultantDetailsMock = {
    employeeId: '621e42de5b5236985cc86339',
    name: 'Test Consultant',
    emailAddress: 'test@consultant.com',
    associatedClients: [
        {
            clientId: '621e42de5b5236985cc8633a',
            clientName: 'Test Client',
            clientNo: '987654321',
            associatedRoleId: '621e42de5b5236985cc8633b'
        }
    ]
};
