<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div *ngIf="!isDialogOpned">
    <p class="gotoList"  [routerLink]="['/project-alpha/hotel-leads/lists']">
        <span class="material-icons icon-size">
            description
            </span>
        <span> Go to List</span>
    </p>
    <h1 class="formHeader">Hotel Leads Form</h1>
</div>
<a *ngIf="isDialogOpned"  class="close-icon" (click)="onCloseClick()" title="close" tabindex="0">
    <mat-icon>close</mat-icon>
</a>
<div mat-dialog-content id="mainSection" class="mat-elevation-z8 mainSection">
<form fxLayout="column" [formGroup]="hotelForm" class="" #ngForm="ngForm" (ngSubmit)="sendBookingInfo()" novalidate>
    <h3 class="center">Customer Profile</h3>
    <div  class="form-group middle-section" formGroupName="customerProfileInfo">
        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayout.xs="column" fxLayoutGap.xs="0em">
            <div fxFlex="30%"  fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <input matInput id="fileId" aria-required="false" type="text" formControlName="customerFileId" (blur)="fetchCustomerData()" class="form-control focusInput textFieldHeight" autocomplete="off" placeholder="Customer File ID"/> 
                </mat-form-field>
                <mat-error *ngIf="hotelForm.controls.customerProfileInfo.get('customerFileId').hasError('invalid')">Please enter valid File ID</mat-error>
            </div>
            <div fxFlex="30%"  fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <input matInput aria-required="false" type="text" formControlName="customerName" class="form-control focusInput textFieldHeight" autocomplete="off" placeholder="Customer Name"/> 
                </mat-form-field>
            </div>
            <div fxFlex="30%"  fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <input matInput aria-required="false" type="email" formControlName="customerEmail" class="form-control focusInput textFieldHeight" autocomplete="off" placeholder="Customer Email"/> 
                </mat-form-field>
            </div>
        </div>
        <div class="input-row" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="3em" fxLayout.xs="column" fxLayoutGap.xs="0em">
            <div fxFlex="30%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <input matInput aria-required="false" type="text" formControlName="bestTimeToCall" class="form-control focusInput textFieldHeight" autocomplete="off" placeholder="Best Time to Call"/> 
                </mat-form-field>
            </div>
            <div fxFlex="30%"  fxLayout.xs="column" fxFlex.xs="100%">
                <section class="checkbox-section">
                    <mat-checkbox
                        class="example-margin"
                        [labelPosition]="'before'"
                        formControlName="premierExecutive"
                    >
                      Premier Executive
                    </mat-checkbox>
                  </section>
            </div>
            <div fxFlex="30%"  fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <mat-label >Geographic Origin</mat-label>
                        <mat-select formControlName="geographicOrigin" class="textFieldHeight">
                            <mat-option *ngFor="let value of geographicOriginValues" [value]="value">
                            {{value}}
                            </mat-option>
                        </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="input-row" fxLayout="row" formGroupName="contactNumber" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayout.xs="column" fxLayoutGap.xs="0em">
                <div fxFlex="30%" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field>
                        <input matInput aria-required="false" type="phone" formControlName="homeNum" class="form-control focusInput textFieldHeight" autocomplete="off" placeholder="Home Phone"/> 
                    </mat-form-field>
                </div>
                <div fxFlex="30%" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field>
                        <input matInput aria-required="false" type="phone" formControlName="cellNum" class="form-control focusInput textFieldHeight" autocomplete="off" placeholder="Cell Phone"/> 
                    </mat-form-field>
                </div>
                <div fxFlex="30%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field>
                        <input matInput aria-required="false" type="phone" formControlName="workNum" class="form-control focusInput textFieldHeight" autocomplete="off" placeholder="Work Phone"/> 
                    </mat-form-field>
                </div>
        </div>
        <mat-error *ngIf="(ngForm.submitted && hotelForm.get('customerProfileInfo').get('contactNumber').invalid)">Please enter atleast any one of the above numbers</mat-error>
        <div class="input-row" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="3em" fxLayout.xs="column" fxLayoutGap.xs="0em">
            <div fxFlex="30%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <textarea matInput aria-required="false" type="text" formControlName="departureLocationDetails" class="form-control focusInput textFieldHeight" autocomplete="off" placeholder="Departure Location City, State/Province, Country"></textarea> 
                </mat-form-field>
            </div>
            <div fxFlex="30%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <input matInput aria-required="false" type="number" formControlName="numberOfGuests" min="0" (keydown)="onKeydownMain($event)" class="form-control focusInput textFieldHeight" autocomplete="off" placeholder="Number of Guests"/> 
                </mat-form-field>
            </div>
        </div>
        <div class="input-row" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="3em" fxLayout.xs="column" fxLayoutGap.xs="0em">
            <div fxFlex="30%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-radio-group
                aria-labelledby="roomTypeOption"
                [ngClass]="{'radio-group-invalid':(ngForm.submitted && hotelForm.get('customerProfileInfo').get('roomType').invalid)}"
                class="radio-group" formControlName="roomType">
                    <mat-label id="roomTypeOption">Room Type</mat-label>
                    <mat-radio-button class="radio-button" [value]="'Standard/Studio'">Standard/Studio
                    </mat-radio-button>
                    <mat-radio-button class="radio-button" [value]="'Studio2Queens'">Studio/2 Queens
                    </mat-radio-button>
                    <mat-radio-button class="radio-button" [value]="'OneBedroomSuite'">One Bedroom Suite
                    </mat-radio-button>
                    <mat-radio-button class="radio-button" [value]="'TwoBedroomSuite'">Two Bedroom Suite
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div fxFlex="30%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-radio-group
                aria-labelledby="parkingOption" formControlName="parking"
                [ngClass]="{'radio-group-invalid':(ngForm.submitted && hotelForm.get('customerProfileInfo').get('parking').invalid)}"
                class="radio-group"  (change)="custRadioBtnChange()" >
                    <mat-label id="parkingOption">Parking</mat-label>
                    <mat-radio-button class="radio-button" [value]="'yes'">Yes
                    </mat-radio-button>
                    <mat-radio-button class="radio-button" [value]="'no'">No
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="isParkingSelected" fxFlex="30%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-radio-group
                aria-labelledby="parkingPaidOption"
                class="radio-group"  formControlName="parkingPaidBy"
                [ngClass]="{'radio-group-invalid':(ngForm.submitted && hotelForm.get('customerProfileInfo').get('parkingPaidBy').invalid)}">
                    <mat-label id="parkingPaidOption">Parking paid by</mat-label>
                    <mat-radio-button class="radio-button" [value]="'cartus'">Cartus
                    </mat-radio-button>
                    <mat-radio-button class="radio-button" [value]="'customer'">Customer
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="input-row" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="3em" fxLayout.xs="column" fxLayoutGap.xs="0em">
            <div fxFlex="30%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-radio-group
                aria-labelledby="petsOption"
                class="radio-group" formControlName="pets" 
                [ngClass]="{'radio-group-invalid':(ngForm.submitted && hotelForm.get('customerProfileInfo').get('pets').invalid)}"
                 (change)="custRadioBtnChange()">
                    <mat-label id="petsOption" >Pets</mat-label>
                    <mat-radio-button class="radio-button" [value]="'yes'">Yes
                    </mat-radio-button>
                    <mat-radio-button class="radio-button" [value]="'no'">No
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="isPetsSelected" fxFlex="30%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <mat-label >Type</mat-label>
                        <mat-select formControlName="petType" (selectionChange)="petTypeChange()" class="textFieldHeight">
                            <mat-option *ngFor="let type of petTypes" [value]="type.name">
                            {{type.value}}
                            </mat-option>
                        </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="input-row" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="3em" fxLayout.xs="column" fxLayoutGap.xs="0em">
            <div *ngIf="isDogSelected && isPetsSelected" fxFlex="30%"  fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <input matInput aria-required="false" type="text" formControlName="petBreed" class="form-control focusInput textFieldHeight" autocomplete="off" placeholder="Breed"/> 
                </mat-form-field>
            </div>
            <div *ngIf="isPetsSelected" fxFlex="30%"  fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <input matInput aria-required="false" type="text" formControlName="weightOfPet" class="form-control focusInput textFieldHeight" autocomplete="off" placeholder="Weight of Pet"/> 
                </mat-form-field>
            </div>
            <div *ngIf="isPetsSelected" fxFlex="30%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <mat-label >Pet Accommodation Paid by</mat-label>
                    <mat-select formControlName="petAccommodationPaidBy" class="textFieldHeight">
                        <mat-option *ngFor="let type of petAccommodationPayTypes" [value]="type.name">
                        {{type.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayout.xs="column" fxLayoutGap.xs="0em">
            <div fxFlex="30%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <input matInput aria-required="false" type="text" formControlName="consultantName" class="form-control focusInput textFieldHeight" autocomplete="off" placeholder="Consultant Name"/> 
                </mat-form-field>
            </div>
            <div fxFlex="30%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <input matInput aria-required="false" type="text" formControlName="consultantPhone" class="form-control focusInput textFieldHeight" autocomplete="off" placeholder="Consultant Phone"/> 
                </mat-form-field>
            </div>
            <div fxFlex="30%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <input matInput aria-required="false" type="email" formControlName="consultantEmail" class="form-control focusInput textFieldHeight" autocomplete="off" placeholder="Consultant Email"/> 
                </mat-form-field>
            </div>
            
        </div>
        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayout.xs="column" fxLayoutGap.xs="0em">
            <div fxFlex="30%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <mat-label >Who to Contact</mat-label>
                        <mat-select formControlName="whoToContact" class="textFieldHeight">
                            <mat-option *ngFor="let type of ContactTransfereeTypes" [value]="type">
                                {{type}}
                            </mat-option>
                        </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <h3 class="center">Client Profile</h3>
    <div  class="form-group middle-section" formGroupName="clientProfileInfo">
        <div class="input-row" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="3em" fxLayout.xs="column" fxLayoutGap.xs="0em">
            <div fxFlex="30%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <input matInput aria-required="false" type="text" formControlName="clientName" class="form-control focusInput textFieldHeight" autocomplete="off" placeholder="Client Name"/> 
                </mat-form-field>
            </div>
            <div fxFlex="30%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <input matInput aria-required="false" type="text" formControlName="clientNumber" class="form-control focusInput textFieldHeight" autocomplete="off" placeholder="Client Number"/> 
                </mat-form-field>
            </div>
            <div fxFlex="30%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-radio-group
                aria-labelledby="billToOption"
                class="radio-group"  formControlName="billTo"
                (change)="billToRadioBtnChange()"
                [ngClass]="{'radio-group-invalid':(ngForm.submitted && hotelForm.get('clientProfileInfo').get('billTo').invalid)}">
                <mat-label id="billToOption">Bill to</mat-label>
                <mat-radio-button class="radio-button"  *ngFor="let field of billToFieldValues" [value]="field.name">{{field.value}}
                </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayout.xs="column" fxLayoutGap.xs="0em">
            <div fxFlex="30%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <input matInput aria-required="false" type="string" formControlName="budget" class="form-control focusInput textFieldHeight" autocomplete="off" placeholder="Nightly Budget"/> 
                </mat-form-field>
            </div>
            <div fxFlex="30%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <mat-label >Currency</mat-label>
                        <mat-select formControlName="currency" class="textFieldHeight">
                            <mat-option *ngFor="let type of currencyTypes" [value]="type.currencyAbbr">
                            {{type.currencyAbbr +' | '+ type.currencyName}}
                            </mat-option>
                        </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="30%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <input matInput aria-required="false" type="email" formControlName="NightCoverdPolicy" class="form-control focusInput textFieldHeight" autocomplete="off" placeholder="Nights Covered by Policy"/> 
                </mat-form-field>
            </div>
        </div>
    </div>
    <h3 class="center">Destination Details</h3>
    <div  class="form-group middle-section" formGroupName="destinationDetailsInfo">
        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayout.xs="column" fxLayoutGap.xs="0em">
            <div fxFlex="30%"  fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <textarea matInput aria-required="false" type="text" formControlName="newWorkAddrs" class="form-control focusInput textFieldHeight" autocomplete="off" placeholder="New Work Address"></textarea> 
                </mat-form-field>
            </div>
            <div fxFlex="30%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <input matInput aria-required="false" type="text" formControlName="destinationCity" class="form-control focusInput textFieldHeight" autocomplete="off" placeholder="Preferred Location City, State/Province, Country"/> 
                </mat-form-field>
            </div>
            <div fxFlex="30%" fxLayoutAlign="start" fxLayout="column" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <input matInput aria-required="false" type="number" min="0" max="365" (keydown)="onKeydownMain($event)" formControlName="lengthOfStay" class="form-control focusInput textFieldHeight" autocomplete="off" placeholder="Length of Stay"/> 
                </mat-form-field>
                <mat-error *ngIf="hotelForm.controls.destinationDetailsInfo.get('lengthOfStay').hasError('max')">Value should not exceed 365</mat-error>
            </div>
        </div>
        <div class="input-row" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="3em" fxLayout.xs="column" fxLayoutGap.xs="0em">
            <div fxFlex="30%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <input matInput aria-required="false" type="date" formControlName="checkInDate" class="form-control focusInput textFieldHeight" autocomplete="off" placeholder="Check in Date"/> 
                </mat-form-field>
            </div>
            <div fxFlex="30%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <textarea matInput aria-required="false" type="email" formControlName="splInstruction" class="form-control focusInput textFieldHeight" autocomplete="off" placeholder="Special Instructions"></textarea> 
                </mat-form-field>
            </div>
        </div>
    </div>
    <h3 class="center">Accommodation Category</h3>
    <div  class="form-group middle-section" formGroupName="accommodationCatInfo">
        <div class="input-row" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayout.xs="column" fxLayoutGap.xs="0em">
                <div fxFlex="50%" fxLayout="column" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%" >
                    <mat-radio-group
                    aria-labelledby="bookingOption"
                    class="radio-group" formControlName="bookingType" (change)="accmdtRadioBtnChange()"
                    [ngClass]="{'radio-group-invalid':(ngForm.submitted && hotelForm.get('accommodationCatInfo').get('bookingType').invalid)}">
                        <mat-label id="bookingOption">Please select one the option below that best suits this booking:</mat-label>
                        <mat-radio-button class="radio-button" [value]="'usDomestic'">US Domestic stay under 30 nights
                        </mat-radio-button>
                        <mat-radio-button class="radio-button" [value]="'internationalOrDomestic'">International or Domestic 30 nights and over
                        </mat-radio-button>
                        <mat-radio-button class="radio-button" [value]="'clientDirected'">Client Directed Stay without Preferred Rates
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="isDirectedInfoDisplay" fxLayout="column" formGroupName="bookingInfo">
                    <div fxFlex="30%" fxLayout="column" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%" >
                        <mat-radio-group
                        aria-labelledby="clientDirectOption"
                        class="radio-group" formControlName="name"
                        [ngClass]="{'radio-group-invalid':(ngForm.submitted && hotelForm.get('accommodationCatInfo').get('bookingInfo').get('name').invalid)}">
                            <mat-label id="clientDirectOption">If Client Directed please select one:</mat-label>
                            <mat-radio-button class="radio-button" [value]="'marriott'">Marriott (US Only)
                            </mat-radio-button>
                            <mat-radio-button class="radio-button" [value]="'lodgingIQ'">LodgingIQ
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div fxFlex="30%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                        <mat-form-field>
                            <textarea matInput aria-required="false" type="text" formControlName="specialInstructions" class="form-control focusInput textFieldHeight" autocomplete="off" placeholder="Client Directed Special Instructions"> </textarea>
                        </mat-form-field>
                    </div>
                </div>
        </div>
    </div>
    <div *ngIf="!isDialogOpned" class="">
        <div class="example-button-row center">
            <button *ngIf="disableNewLead" type="submit" mat-button  color="primary" class="matSubBtn" aria-describedby="info">Submit</button>
        </div>
        <div class="example-button-row center">
            <button *ngIf="!disableNewLead" type="reset" (click)="createNewLead(ngForm)" mat-button class="matSubBtn" color="primary" aria-describedby="info">Create Another Lead</button>
        </div>
    </div>
</form>
</div>