import { Injectable, OnDestroy } from '@angular/core';
import { OktaAuth, AccessToken } from '@okta/okta-auth-js';
import { AppConfigService } from './app-config.service';
import { CookieService } from 'ngx-cookie-service';
import { FeatureFlagService } from './feature-flag.service';
import { urlType } from '../models/urlType';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {
  /** Variable to store the token  */
  public authClient: OktaAuth;
  /** Variable to store the OKta domain */
  public domain: string;
  /** Variable to store the Anywhere Okta feature flag */
  public anywhereAppFlag: boolean = false;

  constructor(
    private readonly cookieService: CookieService,
    private readonly appConfig: AppConfigService,
    private readonly featureFlagService: FeatureFlagService
  ) {
    this.domain = this.cookieService.get('domain');
    this.authClient = new OktaAuth({
      clientId: String(this.appConfig.getConfig('oktaClientId')),
      issuer: String(this.appConfig.getConfig('oktaUrl')),
      authorizeUrl: `${String(this.appConfig.getConfig('oktaUrl'))}/v1/authorize`,
      redirectUri: String(this.appConfig.getConfig('oktaRedirectUri')),
      postLogoutRedirectUri: String(this.appConfig.getConfig('oktaRedirectUri')),
      tokenManager: {
        storage: 'sessionStorage',
        autoRenew: false
      }
    });

    if (this.domain) {
      if (this.domain === 'ANYWHERE') {
        this.checkFeatureFlag();
      }
    }

    this.authClient.authStateManager.subscribe(authState => {
      // handle emitted latest authState
    });
    if (!this.authClient.isLoginRedirect()) {
      // Trigger an initial authState change event when the app startup
      this.authClient.authStateManager.updateAuthState();
    }
  }

  ngOnDestroy(): void {
    if (this.authClient.authStateManager.subscribe) {
      this.authClient.authStateManager.unsubscribe();
    }
  }

  /* Service call to retrieve Anywhere Okta feature flag and reconfigure OktaAuth */
  checkFeatureFlag() {
    (async () => {
      this.anywhereAppFlag = await this.featureFlagService.getFeatureFlag('Anywhere Okta', urlType.accessmgmt);
      if (this.anywhereAppFlag) {
        console.log('Reinitializing auth client with ANYWHERE OKTA Credentials.')
        this.authClient = new OktaAuth({
          clientId: String(this.appConfig.getConfig('realogyOktaClientId')),
          issuer: String(this.appConfig.getConfig('realogyOktaUrl')),
          authorizeUrl: `${String(this.appConfig.getConfig('realogyOktaUrl'))}/v1/authorize`,
          redirectUri: String(this.appConfig.getConfig('oktaRedirectUri')),
          postLogoutRedirectUri: String(this.appConfig.getConfig('oktaRedirectUri')),
          tokenManager: {
            storage: 'sessionStorage',
            autoRenew: false
          }
        });
      }
    })();
  }

  async trySso() {
    const oldToken: AccessToken  = await this.getOldToken();
    try {
      const tokenOrTokens = await this.authClient.token.getWithoutPrompt();
      if (tokenOrTokens) {
        this.authClient.tokenManager.setTokens({
          accessToken: tokenOrTokens.tokens.accessToken,
          idToken: tokenOrTokens.tokens.idToken
        });
        this.revokeOldToken(oldToken);
        console.log('getWithoutPrompt()', tokenOrTokens); // Leave this debug code in place
      }
      return tokenOrTokens;
    } catch (error) {
      console.error(error);
      return;
    }
  }

  /** Service call to get old token */
  async getOldToken() {
    let oldToken: AccessToken;
    await this.authClient.tokenManager.getTokens().then(tokens => {
      if (tokens?.accessToken) {
        oldToken = tokens.accessToken;
      }
    });
    // Should only hit this once upon navigating from logon
    if (!oldToken) {
      return this.decodeTokenFromCookie();
    }
    return oldToken;
  }

  /** Decodes the JWT token stored within a cookie and returns it */
  decodeTokenFromCookie(): AccessToken {
    const encodedToken = this.cookieService.get('car-ses-tok');
    if (encodedToken) {
      try {
        const decodedToken =  JSON.parse(atob(encodedToken.split('.')[1]));
        const constructedToken: AccessToken = {
          accessToken: encodedToken,
          authorizeUrl: `${String(this.appConfig.getConfig('oktaUrl'))}/v1/authorize`,
          claims: {
            ...decodedToken
          },
          expiresAt: decodedToken.exp,
          scopes: decodedToken.scp,
          tokenType: 'bearer',
          userinfoUrl: `${String(this.appConfig.getConfig('oktaUrl'))}/v1/userinfo`,
        };
        return constructedToken;
      } catch (err) {
        console.error(`Unable to decode token: ${err}`);
      }
    }
    return undefined;
  }

  /** Service call to revoke old token */
  async revokeOldToken(accessToken: AccessToken) {
    if (accessToken) {
      console.log('revokeOldToken()');
      this.authClient.revokeAccessToken(accessToken);
    }
  }

  /** Service call for to get tokens */
  async getToken(transactions) {
    let receivedTokens;
    if (transactions && transactions.status === 'SUCCESS') {
      const oldToken: AccessToken = await this.getOldToken();
      await this.authClient.token.getWithoutPrompt({
        responseType: ['code', 'token', 'id_token'],
        sessionToken: transactions.sessionToken,
        scopes: ['openid', 'offline_access', 'email'],
      })
      .then(res => {
        receivedTokens = res.tokens;
        if (receivedTokens.idToken && receivedTokens.accessToken) {
          this.authClient.tokenManager.add('idToken', receivedTokens.idToken);
          this.authClient.tokenManager.add('accessToken', receivedTokens.accessToken);
          this.revokeOldToken(oldToken);
        }
      });
    }
    return {
      transaction: transactions,
      tokens: receivedTokens
    };
  }

  async isAuthenticated() {
    // Checks if there is a current accessToken in the TokenManger.
    return this.authClient.tokenManager.get('accessToken');
  }
}
